<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >
    <el-form-item label="Целевые значения">
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <el-form-item prop="target_a">
            <el-input type="number" v-model="scale.target_a" :disabled="disabled">
              <template slot="prepend">A</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="target_b">
            <el-input type="number" v-model="scale.target_b" :disabled="disabled">
              <template slot="prepend">B</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="target_c">
            <el-input type="number" v-model="scale.target_c" :disabled="disabled">
              <template slot="prepend">C</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item label="Выплаты за достижения, %">
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <el-form-item prop="pay_a">
            <el-input type="number" v-model="scale.pay_a" :disabled="disabled">
              <template slot="prepend">A</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="pay_b">
            <el-input type="number" v-model="scale.pay_b" :disabled="disabled">
              <template slot="prepend">B</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="pay_c">
            <el-input type="number" v-model="scale.pay_c" :disabled="disabled">
              <template slot="prepend">C</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item
      v-if="showFact"
      label="Факт"
      prop="fact"
    >
      <el-input type="number" v-model="scale.fact" :disabled="disabled"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";

export default {
  name: "linear-three-targets-edit-form",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {
    showFact:{
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      scale:{
        target_a: null,
        target_b: null,
        target_c: null,
        pay_a: null,
        pay_b: null,
        pay_c: null,
        fact: null,
      },

      rules:{

      }


    }
  },

  methods: {

  }
}
</script>

<style>

</style>